// 50/50

import Image from 'next/image'
import { forwardRef, MutableRefObject } from 'react'
import clsx from 'clsx'

import { placeholderProps } from 'utils/client'
import { DynamicBackground, HeaderContent, RichText, VideoEmbed } from 'ui'
import { LeadGenForm } from 'ui/form'
import Form from 'ui/form/Form'
import { ImageJsonLd } from 'next-seo'
import type { PageDocumentDataBody5050Slice } from 'types/types.generated'

const FiftyFifty = forwardRef(
	(
		props: PageDocumentDataBody5050Slice,
		_ref: MutableRefObject<HTMLHeadingElement | HTMLParagraphElement>
	) => {
		const { primary, isInGroup, globalData, withClimbers, idx } = props || {}

		const {
			background_color,
			background_height,
			background_squeegee_option,
			section_heading,
			section_description,
			section_primary_cta_label,
			section_primary_cta_link,
			section_secondary_cta_label,
			section_secondary_cta_link,
			text_contrast_mode,
			show_breadcrumb,
			image,
			video,
			with_form,
			form,
			rich_text_box
		} = primary || {}

		return (
			<section
				className={`fifty-fifty relative ${isInGroup ? 'mb-0' : 'section'} 
			${text_contrast_mode === 'light' ? 'text-white text-light' : 'text-black-rt'}
		`}
			>
				{!isInGroup ? (
					<DynamicBackground
						background_color={background_color}
						backgroundHeight={background_height}
						backgroundSqueegeeOption={background_squeegee_option}
					/>
				) : null}

				{/* Main content */}
				<div
					className={clsx(
						'container relative flex lg:gap-20 md:items-center',
						rich_text_box?.length > 0
							? 'flex-col-reverse md:flex-row-reverse'
							: primary.image_on_left
							? 'flex-col md:flex-row'
							: 'flex-col md:flex-row-reverse',
						isInGroup ? 'py-10' : 'pt-12 pb-16'
					)}
				>
					{/* Rich Text Box */}
					{rich_text_box?.length > 0 ? (
						<div className="md:w-1/2 pt-8 md:px-12 xl:py-20 flex flex-col justify-center space-y-7">
							<RichText content={rich_text_box} className="max-w-[546px]" />
						</div>
					) : video?.url || video?.html ? (
						// Video
						<div className="w-full md:w-1/2 aspect-video mx-auto">
							{video?.html ? <VideoEmbed html={video.html} className="video-fifty-fifty" /> : null}
						</div>
					) : // Image
					image?.url ? (
						<div className="relative w-full md:w-1/2 aspect-[66/47] max-h-[470px] mx-auto overflow-hidden flex items-center justify-center rounded-lg">
							<Image
								src={`${primary.image.url}&w=1137&h=705&fit=crop`}
								alt={primary.image.alt || 'image'}
								className="object-cover"
								fill
								sizes="(max-width: 762px) 90vw, (max-width: 1600px) 50vw, 760px"
								{...placeholderProps(primary.image)}
							/>
							<ImageJsonLd
								contentUrl={primary.image.url}
								images={[primary.image]}
								creator="Real Thread"
								creditText="Real Thread"
							/>
						</div>
					) : null}

					{/* Text Content */}
					<div className="md:w-1/2 pt-8 md:px-12 xl:py-20 flex flex-col justify-center space-y-7">
						{/* Climbers */}
						{withClimbers ? (
							<span
								className={clsx(
									'w-12 aspect-square rounded-full flex justify-center items-center text-lg font-medium font-serif -mb-1',
									text_contrast_mode === 'light'
										? 'text-black-rt bg-white'
										: 'text-white bg-black-rt'
								)}
							>
								{idx}
							</span>
						) : null}
						{/* Breadcrumbs, Title, Description, CTAs */}
						<HeaderContent
							showBreadcrumbs={show_breadcrumb}
							title={section_heading}
							description={section_description}
							primaryCta={{ label: section_primary_cta_label, link: section_primary_cta_link }}
							secondaryCta={{
								label: section_secondary_cta_label,
								link: section_secondary_cta_link
							}}
							textContrast={text_contrast_mode}
							centered={false}
							className="!pb-0"
							globalData={globalData}
						/>

						{/* Render form if with_form is true */}
						{with_form ? (
							form.type === 'lead_gen_form' ? (
								<div className="flex flex-col items-center w-full">
									<LeadGenForm form={form} />
								</div>
							) : (
								<Form form={form} alignButtonOnLeft />
							)
						) : null}
					</div>
				</div>
			</section>
		)
	}
)
FiftyFifty.displayName = 'FiftyFifty'

export default FiftyFifty
